
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';

const Header = () => {
  //navbar color change
  // const [navchange, setNavbar] = useState(false);
  // const changebackground = () => {
  //   if (window.scrollY >= 250) {
  //     setNavbar(true)
  //   }
  //   else {
  //     setNavbar(false);
  //   }
  // }

  // window.addEventListener('scroll', changebackground)

  // bookappointment script 
  const form = useRef()
  const [data, updateData] = useState({
    Name: "",
    Email: '',
    Number: '',
    Subject: '',
    Message: ''
  })

  const { Name, Email, Number, Subject, Message } = data;

  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_d7nkc98', 'template_dhs0a87', e.target, '-_ajGXvF0DTOYDkQE')
      .then((result) => {
        alert("Successfully Booked Your Appointment ")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Redirect links...

  const [home, sethome] = useState(false);
  const [about, setabout] = useState(false);
  const [contact, setcontact] = useState(false);
  const [Blog, setBlog] = useState(false);
  const [images, setImages] = useState(false);
  const [videos, setVideos] = useState(false);
  const [cid, setcid] = useState(false);
  const [Live, setLive] = useState(false);
  const [sepsis, setsepsis] = useState(false);
  const [fever, setFever] = useState(false);
  const [Friday, setfriday] = useState(false);
  const [kalyan, setkalyan] = useState(false);
  const [Presentations, setpresntation] = useState(false);

  if (images) {
    return <div>
      <Header />
      <return to='/Images' />
    </div>
  }
  

  if (Presentations) {
    return <div>
      <Header />
      <return to='/Presentations_and_Publications' />
    </div>
  }
  if (Friday) {
    return <div>
      <Header />
      <return to='/Friday_Id_Day' />
    </div>
  }

  if (videos) {
    return <div>
      <Header />
      <return to='/Videos' />
    </div>
  }

  if (cid) {
    return <div>
      <Header />
      <return to='/BEST_CENTRE_FOR_INFECTIOUS_DISEASES' />
    </div>
  }

  if (Live) {
    return <div>
      <Header />
      <return to='/Lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur' />
    </div>
  }

  if (sepsis) {
    return <div>
      <Header />
      <return to='/Sepsis_and_Infectious_Diseases' />
    </div>
  }

  if (fever) {
    return <div>
      <Header />
      <return to='/Best_Fever_Hospital_in_guntur' />
    </div>
  }



  if (about) {
    return <div>
      <Header />
      <return to='/about' />
    </div>
  }
  if (Blog) {
    return <div>
      <Header />
      <return to='/Blogs' />
    </div>
  }

  if (contact) {
    return <div>
      <Header />
      <return to='/contact' />
    </div>
  }

  if (kalyan) {
    return <div>
      <Header />
      <return to='/Dr_Kalyan_Chakravarthy_Koganti' />
    </div>
  }


  if (home) {
    return <div>
      <Header />
      <return to='/Home' />
    </div>
  }




  return (

    <>
      <nav>
        <div className='iconss' sticky="top">
          <div className='row'>
            <div className='col-md-8' style={{ paddingTop: '8px' ,paddingBottom:'5px'}} >
              {/* <a href="#" ><button className="btn" onClick={handleShow} style={{ color: 'white', border: 'none', textAlign: 'center', textDecoration: 'none', display: ' inline-block', fontSize: '16px', backgroundColor: 'rgb(245, 41, 41)' }}>Book Appointment</button></a> */}
              {/* <div  className="dropdown" >
              <button className="dropbtn" style={{marginRight:'30px'}}>Appointment</button>
              <div className="dropdown-content " >
                <a  href="tel:9000 10">link1</a>
                <a  href="tel:70326 " style={{marginLeft:'-100px'}}>link2</a>
                
              </div>
              </div> */}
              {/* <a href=''><i class="bi bi-phone-flip">Emergency Call</i></a> */}
              {/* <div  className="dropdown" >
              <button className="dropbtn">Emergency Call</button>
              <div className="dropdown-content " >
              <a  href="tel:9000 1">link1</a>
                <a  href="tel:70326" style={{marginLeft:'-100px'}}>link2</a>
                
              </div>
              </div> */}
              <a href="tel:9000 100 108" className='emergency1' ><b>Emergency</b></a>

              <a href="tel:0863 226 5559" className='emergency2' style={{}}><b>Appointment</b></a>

            </div>
            <div className='col-md-4 text-center mt-2 emergency' style={{ justifyContent: 'center' }}>
              {/* <Link to='https://www.facebook.com/drkalyanchakravarthykogantihelphospital/' title='Facebook'><i className="bi bi-facebook"></i></Link>
              <Link to='https://www.linkedin.com/in/dr-kalyan-chakravarthy-koganti-4b6ab0232?originalSubdomain=in' title='Linkedin'><i className='bi bi-linkedin'></i></Link>
              <Link to='https://www.youtube.com/channel/UCekR_-Ad9pYEbsumIDBFJsA' title='Youtube'><i className="bi bi-youtube"></i></Link>
              <Link to='https://www.instagram.com/dr.kalyanchakravarthykoganti/' title='Instagram' ><i className="bi bi-instagram"></i></Link> */}
                <img src='insu6.webp' className='insuranceLogo'></img>
              <Link to="insurance" style={{textDecoration:'none',marginLeft:'-1px'}}>All INSURANCES AVAILABLE</Link>

            </div>
          </div>
        </div>
        <div className="wrapper1" sticky="top"  >
          <div className="logo"><Link to="#"><img src='web Logo output eng.png' alt='' style={{ width: '340px',height:'300px', marginLeft: '-25px',marginTop:'-8px' }}></img></Link></div>

          <img className='mobilelogo' src='logggg.png' alt='' ></img>
          <div className='mobileLinks' >
            <Link className='em1' to="tel:9000 100 108"><b>Emergency</b></Link><br></br>
          </div>
          <div className='mobileLinks1'>
            <Link className='em2' to="tel:08632 265 559"><b>Appointment</b></Link>
          </div>

          <div className='mobileInsurance'>
            <img src='insu6.webp' alt=''></img>
            <Link className='insurance' to="/Insurance">ALL INSURANCES AVAILABLE</Link>
          </div>
          

          {/* <div >
            <img className='mobileLinks' src='logggg.png' alt='' style={{width:'38%'}}></img>

            </div>
            <div >
              <button><Link className='mobileLinks' to="tel:9000 100 108">Emergency</Link></button>
              <button><Link className='mobileLinks' to="tel:8632 265 559">Appointment</Link></button>
            </div>
            <Link className='mobileLinks' to="/Insurance"><img src='insu6.webp' alt='' style={{width:'15%',}}></img></Link>

          */}


          <input type="radio" name="slider" placeholder='null' title='menu' id="menu-btn" />
          <input type="radio" name="slider" placeholder='null' title='close' id="close-btn" />
          <ul className="nav-links">
            <li><label htmlFor="close-btn" style={{ color: 'black', width: '20px' }} className="btn close-btn"><i className="bi bi-x-circle"></i></label></li>
            <li><Link to="/Home" onClick={() => sethome(true)} style={{ color: 'black', fontSize: '14px' }}>Home</Link></li>
            <li>
              <Link to="#" className="desktop-item" onClick={() => setabout(true)} style={{ color: 'black', fontSize: '14px' }}>About</Link>
              <input type="checkbox" id="showDrop" />
              <label htmlFor="showDrop" className="mobile-item" style={{ color: 'black', fontSize: '14px' }}>About</label>
              <ul className="drop-menu">
                <li><Link to="/Dr_Kalyan_Chakravarthy_Koganti" onClick={() => setkalyan(true)}>Dr Kalyan Chakravarthy Koganti</Link></li>
                <li><Link to="/Presentations_and_Publications" onClick={() => setpresntation(true)}>Presentations and Publications</Link></li>
              </ul>
            </li>
            {/* <li><Link to="#" style={{ color: 'black',fontSize:'15px' }}>Gallery</Link></li> */}
            <li>
              <Link href="#" className="desktop-items" style={{ color: 'black', fontSize: '14px' }}>Gallery</Link>
              <input type="checkbox" id="showDrops" />
              <label htmlFor="showDrops" className="mobile-item" style={{ color: 'black', fontSize: '14px' }}>Gallery</label>
              <ul className="drop-menus">
                <li><Link to="Images" onClick={()=>setImages(true)}>Images</Link></li>
                <li><Link to="Videos" onClick={()=>setVideos(true)}>Videos</Link></li>
              </ul>
            </li>
            <li><Link to="/News" style={{ color: 'black', fontSize: '14px' }} onClick={()=>setBlog(true)}>News</Link></li>
            <li>
              <Link href="#" className="desktop-items" style={{ color: 'black', fontSize: '14px' }}>Specialities</Link>
              <input type="checkbox" id="showDropelink" />
              <label htmlFor="showDropelink" className="mobile-item" style={{ color: 'black', fontSize: '14px' }}>Specialities</label>
              <ul className="drop-menulink">
                <li><Link to="/BEST_CENTRE_FOR_INFECTIOUS_DISEASES" onClick={()=>setcid(true)}>CID</Link></li>
                <li><Link to="/Lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur" onClick={()=>setLive(true)}>LIVE</Link></li>
                <li><Link to="/Sepsis_and_Infectious_Diseases" onClick={()=>setsepsis(true)}>Sepsis</Link></li>
                <li><Link to="/Best_Fever_Hospital_in_guntur" onClick={()=>setFever(true)}>Fever</Link></li>
              </ul>
            </li>
            <li><Link to="Contact" style={{ color: 'black', fontSize: '14px' }} onClick={() => setcontact(true)}>Contact</Link></li>
            <li><Link to="Friday_Id_Day" style={{ color: 'black', fontSize: '14px' }} onClick={() => setfriday(true)}>Friday Id Day</Link></li>

            <Link to="#" ><button className="btn" onClick={handleShow} style={{ color: 'white', border: 'none', padding: '15px 32px', textAlign: 'center', textDecoration: 'none', display: ' inline-block', fontSize: '16px', margin: '4px 2px', backgroundColor: 'rgb(245, 41, 41)' }}>Book Appointment</button></Link>

          </ul>
          <label htmlFor="menu-btn" className="btn menu-btn" style={{ color: 'white',}}><i className="bi bi-list" style={{ blockSize: '100px' }} ></i></label>
        </div>
      </nav>

      <Modal style={{ marginTop: '50px' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center' style={{ color: 'rgb(245, 41, 41)', textAlign: 'center' }}>Book An Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Subject} onChange={changeHandler} className="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >

              <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
              <div className="help-block with-errors"></div>
            </Form.Group>
            <button type="submit" className="btn " name="contact" style={{ backgroundColor: "rgb(245, 41, 41)", color: "whitesmoke" }} >Send Message</button>
            <div id="msgSubmit" className="h3 text-center hidden"></div>
            <div className="clearfix"></div>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default Header